


























































































import Vue from "vue";
import ImagenPublicidadAdd from "@/components/admin/evento/carrera/publicidad/ImagenPublicidadAdd.vue";
import BannerPublicitarioCarreraService from "@/api/carrera/detalle/BannerPublicitarioCarreraService";
import { isArray } from "class-validator";
export default Vue.extend({
  components: {
    ImagenPublicidadAdd
  },
  data: () => ({
    dialog: false,
    dato: {
      id: 0,
      url: ""
    },
    datos: []
  }),
  methods: {
    editItem(item: { id: number; url: string }) {
      this.dato = { id: item.id, url: item.url };
      this.dialogChange(true);
    },
    dialogChange(value: boolean) {
      this.dialog = value;
    },
    getBanners() {
      BannerPublicitarioCarreraService.getBanners(this.$route.params.id)
        .then(response => {
          this.datos = response.data;
        })
        .catch(() => {
          console.log("No se cargaron los banners");
        }); //fin get Banners
    },
    getBanner(fileName: string) {
      return BannerPublicitarioCarreraService.getImageUrl(fileName);
    },
    deleteImagen(idBanner: number) {
      BannerPublicitarioCarreraService.deleteImagen(idBanner)
        .then(() => {
          console.log("Se pudo borrar la imagen");
          this.datos = [];
          this.getBanners();
        })
        .catch(() => {
          console.log("No se pudo borrar la imagen");
        });
    }
  },
  computed: {
    listaDatos() {
      if (this.datos && isArray(this.datos)) {
        return this.datos.filter(
          (c: any) => !['bannerResponsive', 'bannerPublicidad'].includes(c.name) );
      }
      return [];
    }
  },
  mounted() {
    this.getBanners();
  }
});
