import httpClient from "@/api/http-client";

const ENDPOINT = "/evento/carrera/distancia";

export interface DistanciaCarreraCreateDto {
  id?: number;
  nombre: string;
  distancia: number;
}

class DistanciaCarreraService {
  getAll(idCarrera: string): Promise<DistanciaCarreraCreateDto[]> {
    return httpClient.get(`${ENDPOINT}/${idCarrera}`).then(response => {
      if (response.data) {
        return response.data;
      }
    });
  }
  save(idCarrera: string, createDto: DistanciaCarreraCreateDto) {
    console.log(createDto);
    return httpClient.post(`${ENDPOINT}/editable/${idCarrera}`, createDto);
  }

  delete(carreraID: string | number, entidadID: string | number) {
    return httpClient.delete(`${ENDPOINT}/${carreraID}/${entidadID}`);
  }
}
export default new DistanciaCarreraService();
