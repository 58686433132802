















































































































































//import CategoriaDistanciaCarreraAdd from "./DistanciaCarreraAdd.vue";
import Vue from "vue";
/*
import DistanciaCarreraService, {
  DistanciaCarreraCreateDto
} from "@/api/carrera/detalle/DistanciaCarrera.service";
*/

//import DistanciaService, { DistanciaCreateDto } from "@/api/DistanciaService";
import RegistradosPendientesEntregaAdd from "./RegistradosPendientesEntregaAdd.vue";
import RegistroCarreraService, {
  RegistroCarreraCreateDto
} from "@/api/carrera/detalle/RegistroCarreraService";
import XLSX from "xlsx";
import moment from "moment";

export default Vue.extend({
  components: {
    //CategoriaDistanciaCarreraAdd
    RegistradosPendientesEntregaAdd
  },
  data() {
    return {
      search: "",
      dialog: false,
      /*
      distanciasBase: [] as DistanciaCreateDto[],
      dato: {
        nombre: ""
      } as DistanciaCarreraCreateDto,
      */
      headers: [
        { text: "#", value: "id" },
        { text: "Identificación", value: "persona.identificacion" },
        //{ text: "Nombre", value: "persona.nombre" },
        //{ text: "Apellido", value: "persona.apellido" },
        { text: "Nombre Registrado", value: "persona.displayNombreCompleto" },
        //{ text: "Distancia", value: "distancia.nombre" },
        //{ text: "Categoria", value: "categoria.nombre" },
        //{ text: "Talla", value: "tallaCamiseta.talla.nombre" },
        //{ text: "Personalización", value: "personalizacionCamiseta" },
        { text: "Kit", value: "kitEntregable", sortable: false, width: "10%" },
        {
          text: "Entregar a",
          value: "pedido.direccionEnvio.displayNombreCompleto"
        },
        { text: "En Provincia", value: "pedido.direccionEnvio.provincia" },
        { text: "En Cantón", value: "pedido.direccionEnvio.canton" },
        { text: "Al Teléfono", value: "pedido.direccionEnvio.telefono" },
        {
          text: "En Dirección",
          value: "pedido.direccionEnvio.direccion",
          width: "10%"
        },
        {
          text: "Referencia",
          value: "pedido.direccionEnvio.referencia",
          width: "10%"
        },
        { text: "Ubicación", value: "ubicacion", sortable: false },
        { text: "Acción", value: "accion", sortable: false }

        //{ text: "Teléfono", value: "telefono", sortable: false }
        //{ text: "Precio", value: "precio.nombre" }
        //{ text: "Estado", value: "estado" },
        //{ text: "createdOn", value: "createdOn", sortable: false },
        //{ text: "Acción", value: "actions", sortable: false }
      ],
      datos: [] as RegistroCarreraCreateDto[],
      totalRecaudado: "0.00",
      dato: {
        id: 0
      } as RegistroCarreraCreateDto
    };
  },
  methods: {
    getMapUrl(value: any) {
      let url = "";
      try {
        //http://www.google.com/maps/place/lat,lng
        const loc = JSON.parse(value);
        if (loc && loc.lat && loc.lng) {
          url = `http://www.google.com/maps/place/${loc.lat},${loc.lng}`;
        } else {
          throw new Error("Coordenada no es valida");
        }
      } catch {
        //
      }
      return url;
    },
    loadTotalRecaudado() {
      let total = 0;
      for (const doc of this.datos) {
        const p = doc as any;
        total += Number(p.precio.precio);
      }
      this.totalRecaudado = total.toFixed(2);
    },
    exportExcel() {
      //const data = XLSX.utils.json_to_sheet(this.datos);
      const data = XLSX.utils.table_to_sheet(
        document.getElementById("tablita")
      );
      const workbook = XLSX.utils.book_new();
      const filename = "carrera-registros-pagados";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    setDefaultItem() {
      const newDto = {
        nombre: "",
        distancia: 0,
        edadMinima: 0,
        edadMaxima: 0
      };
      //this.dato = newDto;
    },

    editItem(item: RegistroCarreraCreateDto) {
      this.dato = item;
      this.dialogChange(true);
    },
    /*
    newItem() {
      this.setDefaultItem();
      this.dialogChange(true);
    },
    */
    dialogChange(value: boolean) {
      this.dialog = value;
    },
    /*
    entregar(idRegistro: number) {
      RegistroCarreraService.entregarRegistroPendienteEntrega(idRegistro)
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Entrega",
            text: "Se guardo correctamente "
          });
          //
        })
        .catch(() => {
          this.$swal({
            icon: "error",
            title: "Entrega",
            text: "No se pudo guardar"
          });
        })
        .finally(() => {
          this.getAll();
        });
    },
    */
    getAll() {
      RegistroCarreraService.getRegistrosPendientesEntrega(
        this.$route.params.id
      )
        .then(response => {
          this.datos = response;
          //this.loadTotalRecaudado();
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Registros Pagados",
            text: "No se pudo obtener"
          });
        });
    },
    downloadExport() {
      RegistroCarreraService.getRegistrosPagadosExcel(this.$route.params.id)
        .then(response => {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(new Blob([response.data]));
          const today = new Date();
          moment.locale("es");
          link.setAttribute(
            "download",
            `carrera-${this.$route.params.id}_${moment(today).format(
              "YYYY-MMMM-DD"
            )}.xlsx`
          ); //"carrera-reporte.xlsx"
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          console.log("no se pudo generar el documento");
        });
    }
  },
  mounted() {
    this.getAll();
  },
  watch: {
    dialog: function(newVal) {
      if (newVal === false) {
        this.setDefaultItem();
      }
    }
  }
});
