










































































































































































































import Vue from "vue";
import DistanciaCarreraService, {
  DistanciaCarreraCreateDto
} from "@/api/carrera/detalle/DistanciaCarrera.service";
import CategoriaCarreraService, {
  CategoriaCarreraCreateDto
} from "@/api/carrera/detalle/CategoriaCarrera.service";
import TallaCamisetaCarreraService from "@/api/carrera/detalle/TallaCamisetaCarreraService";

import RegistroCarreraService from "@/api/carrera/detalle/RegistroCarreraService";
export default Vue.extend({
  props: ["dialog", "dato"],
  data() {
    return {
      saving: false,
      // nuevos
      codigoChip: "",
      entregadoA: "",
      numeroCamiseta: "",
      isEntregarOtraPersona: false,
      //fin nuevos

      editedItem: {
        id: 0
      },
      distancias: [] as DistanciaCarreraCreateDto[],
      categorias: [] as CategoriaCarreraCreateDto[],
      tallas: [] as any[],
      switch1: false,
      documento: {
        talla: null,
        distancia: null,
        categoria: null,
        // new
        codigoChip: null,
        numeroCamiseta: null
      }
    };
  },
  mounted() {
    this.loadDistancias();
    this.loadCategorias();
    this.loadTallas();
    this.editedItem = { ...this.dato };
    //this.loadPapeleta();
  },
  methods: {
    save() {
      if (this.saving) {
        return;
      }
      this.saving = true;
      RegistroCarreraService.modificarRegistroPagado(
        this.editedItem.id,
        this.documento
      )
        .then(() => {
          this.close();
          this.$emit("saved", "someValue");
          this.$swal({
            icon: "success",
            title: "Registro Modificado",
            text: "Se guardo correctamente "
          });
        })
        .catch(error => {
          let mensaje = "No se puedo guardar";
          if (error.response && error.response.data) {
            mensaje = error.response.data.message;
          }
          this.$swal({
            icon: "error",
            title: "Registro Modificado",
            text: mensaje
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    dialogChange(value: boolean) {
      this.$emit("dialogChange", value);
    },
    close() {
      this.dialogChange(false);
    },
    loadDistancias() {
      DistanciaCarreraService.getAll(this.$route.params.id)
        .then(response => {
          this.distancias = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Distancias",
            text: "No se pudo obtener"
          });
        });
    },
    loadCategorias() {
      CategoriaCarreraService.getAll(this.$route.params.id)
        .then(response => {
          this.categorias = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Categorias",
            text: "No se pudo obtener"
          });
        });
    },
    loadTallas() {
      TallaCamisetaCarreraService.getTallas(this.$route.params.id)
        .then(response => {
          const tallasStock = [];
          if (response) {
            for (const tallaCamiseta of response) {
              const stock: number =
                tallaCamiseta.articuloInventario.stockAvailable;
              if (stock && stock > 0) {
                tallasStock.push(tallaCamiseta);
              }
            }
            this.tallas = tallasStock;
          }
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Tallas Editables",
            text: "No se pudo obtener"
          });
        });
    }
  },
  computed: {
    isPresencial(): boolean {
      return (
        this.dato.carrera.modalidad === "P" ||
        this.dato.carrera.modalidad === "PCV"
      );
    }
  },
  watch: {}
});
