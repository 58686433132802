



















import Vue from "vue";
import ImagenPublicidad from "@/components/admin/evento/carrera/publicidad/ImagenPublicidad.vue";
import TallaCamiseta from "@/components/admin/evento/carrera/talla/TallaCamiseta.vue";
import PrecioCarrera from "@/components/admin/evento/carrera/precio/PrecioCarrera.vue";
import Auspiciante from "@/components/admin/evento/carrera/auspiciante/Auspiciante.vue";
import DistanciaCarrera from "@/components/admin/evento/carrera/distancia/DistanciaCarrera.vue";
import CategoriaCarrera from "@/components/admin/evento/carrera/categoria/CategoriaCarrera.vue";
import RegistradosPagados from "@/components/admin/evento/carrera/registrados/RegistradosPagados.vue";
import RegistradosTodos from "@/components/admin/evento/carrera/registrados/RegistradosTodos.vue";
import RegistrosPendientesPago from "@/components/admin/evento/carrera/registrados/RegistrosPendientesPago.vue";
import KitCarrera from "@/components/admin/evento/carrera/kit/KitCarrera.vue";
import ResumenCarrera from "@/components/admin/evento/carrera/resumen/ResumenCarrera.vue";
import RegistradosPendientesEntregaVirtual from "@/components/admin/evento/carrera/registrados/RegistradosPendientesEntregaVirtual.vue";
import RegistradosPendientesEntregaPresencial from "@/components/admin/evento/carrera/registrados/RegistradosPendientesEntregaPresencial.vue";
import Cupones from "@/components/admin/evento/cupon/Cupones.vue";
import Recorrido from "@/components/admin/evento/carrera/recorrido/Recorrido.vue";
import Citas from "@/components/admin/evento/carrera/citas/Citas.vue";
import Galeria from "@/components/admin/evento/carrera/galeria/Galeria.vue";
import Resultados from "@/components/admin/evento/carrera/resultados/Resultados.vue";
import RegistroFromExcel from "@/components/admin/evento/carrera/registro-from-excel/RegistroFromExcel.vue";
import AuthStore from "@/store/modules/AuthStore";
import { isIn } from "class-validator";
export default Vue.extend({
  components: {
    ResumenCarrera: ResumenCarrera,
    ImagenPublicidad: ImagenPublicidad,
    TallaCamiseta: TallaCamiseta,
    KitCarrera: KitCarrera,
    PrecioCarrera: PrecioCarrera,
    Auspiciante: Auspiciante,
    CategoriaCarrera: CategoriaCarrera,
    DistanciaCarrera: DistanciaCarrera,
    RegistradosPagados: RegistradosPagados,
    RegistradosTodos: RegistradosTodos,
    RegistrosPendientesPago: RegistrosPendientesPago,
    RegistradosPendientesEntregaVirtual: RegistradosPendientesEntregaVirtual,
    RegistradosPendientesEntregaPresencial: RegistradosPendientesEntregaPresencial,
    Cupones: Cupones,
    Recorrido: Recorrido,
    // Citas: Citas,
    Galeria: Galeria,
    Resultados: Resultados,
    RegistroFromExcel: RegistroFromExcel,
  },
  data() {
    return {
      activeTab: null,
      lista: [
        { title: "Resumen", component: ResumenCarrera, roles: ["1"] },
        { title: "Distancias", component: DistanciaCarrera, roles: ["1"] },
        { title: "Categorias", component: CategoriaCarrera, roles: ["1"] },
        {
          title: "Tallas Camiseta",
          component: TallaCamiseta,
          roles: ["1"]
        },
        { title: "Kits", component: KitCarrera, roles: ["1"] },
        { title: "Precios", component: PrecioCarrera, roles: ["1"] },
        {
          title: "Pendiente Pago",
          component: RegistrosPendientesPago,
          roles: ["1", "2"]
        },
        {
          title: "No Entregado Virtual",
          component: RegistradosPendientesEntregaVirtual,
          roles: []
        },
        {
          title: "No Entregado Presencial",
          component: RegistradosPendientesEntregaPresencial,
          roles: ["1", "2"]
        },
        { title: "Pagados", component: RegistradosPagados, roles: ["1", "2"] },
        { title: "Todos", component: RegistradosTodos, roles: ["1", "2"] },
        // { title: "Citas", component: Citas, roles: ["1", "2"] },
        { title: "Auspiciantes", component: Auspiciante, roles: ["1"] },
        { title: "Publicidad", component: ImagenPublicidad, roles: ["1"] },
        { title: "Recorrido", component: Recorrido, roles: ["1"] },
        { title: "Galeria", component: Galeria, roles: ["1"] },
        { title: "Cupones", component: Cupones, roles: ["1"] },
        { title: "Registro Excel", component: RegistroFromExcel, roles: ["1"] },
        { title: "Resultados", component: Resultados, roles: ["1"] }
      ] as any[]
    };
  },
  computed: {
    getRole(): string {
      if (AuthStore.getLoggedIn && AuthStore.getRole) {
        return AuthStore.getRole;
      } else {
        return "";
      }
    },
    tabs(): any[] {
      return this.lista.filter(c => isIn(this.getRole, c.roles));
    }
  }
});
