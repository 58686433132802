























































import KitCarreraAdd from "./KitCarreraAdd.vue";
import Vue from "vue";

import KitCarreraService, {
  KitCreateDto
} from "@/api/carrera/detalle/KitCarreraService";

export default Vue.extend({
  components: {
    KitCarreraAdd
  },
  data() {
    return {
      dialog: false,
      dato: {
        nombre: "",
        cantidad: 0,
        entrada: 0
      } as KitCreateDto,
      headers: [
        { text: "Id", value: "id" },
        { text: "Nombre", value: "nombre" },
        { text: "Cantidad por Persona", value: "cantidad" },
        {
          text: "Stock Disponible",
          value: "articuloInventario.stockAvailable"
        },
        {
          text: "Stock Bodega",
          value: "articuloInventario.stockOnHand"
        },

        { text: "Estado", value: "estado", sortable: false },
        //{ text: "Estado", value: "estado" },
        //{ text: "createdOn", value: "createdOn", sortable: false },
        { text: "Acción", value: "actions", sortable: false }
      ],
      datos: [] as KitCreateDto[]
    };
  },
  methods: {
    setDefaultItem() {
      const newDto = {
        nombre: "",
        cantidad: 1,
        entrada: 0
      };
      this.dato = newDto;
    },
    editItem(item: KitCreateDto) {
      this.dato = item;
      this.dialogChange(true);
    },
    newItem() {
      this.setDefaultItem();
      this.dialogChange(true);
    },
    dialogChange(value: boolean) {
      this.dialog = value;
    },
    getAll() {
      KitCarreraService.getAll(this.$route.params.id)
        .then(response => {
          this.datos = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Kits",
            text: "No se pudo obtener"
          });
        });
    }
  },
  mounted() {
    this.getAll();
  },
  watch: {
    dialog: function(newVal) {
      if (newVal === false) {
        this.setDefaultItem();
      }
    }
  }
});
