














































import PrecioCarreraAdd from "./PrecioCarreraAdd.vue";
import Vue from "vue";
import PrecioCarreraService, {
  PrecioCreateDto
} from "@/api/carrera/detalle/PrecioCarreraService";

export default Vue.extend({
  components: {
    PrecioCarreraAdd
  },
  data() {
    return {
      dialog: false,
      dato: {
        nombre: "",
        descripcion: ""
      } as PrecioCreateDto,
      headers: [
        { text: "Id", value: "id" },
        { text: "Nombre", value: "nombre" }, // disp
        { text: "Descripción", value: "descripcion" }, // disp
        { text: "Precio", value: "precio" }, // disp
        { text: "Visible", value: "mostrarDisplay" }, // disp
        { text: "Descriptor", value: "tipoDescriptorDisplay" }, // disp
        { text: "Pos.", value: "position" },
        //{ text: "Estado", value: "estado" },
        //{ text: "createdOn", value: "createdOn", sortable: false },
        { text: "Acción", value: "actions", sortable: false }
      ],
      datos: [] as PrecioCreateDto[]
    };
  },
  methods: {
    setDefaultItem() {
      const newDto = {
        nombre: "",
        descripcion: "",
        tipoDescriptor: "5",
        precio: 0.0,
        mostrar: "1",
        position: 0,
      };
      this.dato = newDto;
    },
    editItem(item: PrecioCreateDto) {
      this.dato = item;
      if (this.dato.fechaVigencia) {
        this.dato.fechaVigencia = new Date(this.dato.fechaVigencia);
      }
      this.dialogChange(true);
    },
    newItem() {
      this.setDefaultItem();
      this.dialogChange(true);
    },
    dialogChange(value: boolean) {
      this.dialog = value;
    },
    getAll() {
      PrecioCarreraService.getAll(this.$route.params.id)
        .then(response => {
          this.datos = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Precios",
            text: "No se pudo obtener"
          });
        });
    }
  },
  mounted() {
    this.getAll();
  },
  watch: {
    dialog: function(newVal) {
      if (newVal === false) {
        this.setDefaultItem();
      }
    }
  }
});
