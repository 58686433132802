var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.dialog)?_c('ImagenPublicidadAdd',{attrs:{"dialog":_vm.dialog,"dato":_vm.dato},on:{"saved":function($event){return _vm.getBanners()},"dialogChange":_vm.dialogChange}}):_vm._e()],1),_vm._l((_vm.listaDatos),function(card){return _c('v-col',{key:card.title,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',[_c('v-img',{staticClass:"white--text align-end",attrs:{"src":_vm.getBanner(card.fileName),"gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)","height":"200px","contain":""}},[_c('v-card-title',{domProps:{"textContent":_vm._s(card.title)}})],1),_c('v-card-text',[_c('v-row',[(card.name === 'vistaPrevia')?_c('v-col',{attrs:{"cols":"12"}},[_c('b',[_vm._v("Relación de aspecto:")]),_vm._v(" 16:9 "),_c('br'),_c('b',[_vm._v("Tamaño recomendado:")]),_vm._v(" 800x450 o superior ")]):_vm._e(),([
                'imagenPrincipal',
                'imagenCamiseta', 'camisetaPersonalizada', 'imagenMedalla', 'imagenKit', 'imagenCategorias', 'productosAuspiciantes'
                ].includes(card.name))?_c('v-col',{attrs:{"cols":"12"}},[_c('b',[_vm._v("Relación de aspecto:")]),_vm._v(" 1:1 "),_c('br'),_c('b',[_vm._v("Tamaño recomendado:")]),_vm._v(" 800x800 o superior ")]):_vm._e(),([
                'banner',
                'publicidad1',
                'publicidad2',
                'publicidad3',
                'publicidad4',
                'proximasBanner1',
                'organizadasBanner1',

                // estos no se usan
                'bannerResponsive',
                'bannerPublicidad' ].includes(card.name))?_c('v-col',{attrs:{"cols":"12"}},[_c('b',[_vm._v("Relación de aspecto:")]),_vm._v(" 3:1 "),_c('br'),_c('b',[_vm._v("Tamaño recomendado:")]),_vm._v(" 1800x600 o superior ")]):_vm._e(),(card.name === 'miniBanner')?_c('v-col',{attrs:{"cols":"12"}},[_c('b',[_vm._v("Relación de aspecto:")]),_vm._v(" 5.6:1 "),_c('br'),_c('b',[_vm._v("Tamaño recomendado:")]),_vm._v(" 504x90 o superior ")]):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),(card.fileName)?_c('v-btn',{attrs:{"icon":"","href":_vm.getBanner(card.fileName),"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-cloud-download")])],1):_vm._e(),(card.fileName)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteImagen(card.id)}}},[_c('v-icon',[_vm._v("mdi-image-remove")])],1):_vm._e(),(card.url)?_c('v-btn',{attrs:{"icon":"","href":card.url,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-share-variant")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(card)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }