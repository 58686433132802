


























































































import Vue from "vue";
import DistanciaCarreraService from "@/api/carrera/detalle/DistanciaCarrera.service"; //DistanciaCarreraCreateDto
import { isEmpty, isNotEmpty, isNumber } from "class-validator";

export default Vue.extend({
  props: ["dialog", "dato", "distancias"],
  data() {
    return {
      editedIndex: -1,
      editedItem: {
        nombre: "",
        distancia: 0,
        edadMinima: 0,
        edadMaxima: 0,
        position: 0,
        isGeneralM: false,
        isGeneralF: false
      },
      tipoParticipanteOptions: [
        { text: "Hombre", value: "M" },
        { text: "Mujer", value: "F" },
        { text: "Mixto", value: "T" }
      ]
    };
  },
  mounted() {
    this.editedItem = { ...this.dato };
  },
  methods: {
    onChangeDistancia() {
      if (this.editedItem.distancia > 0) {
        let palabra = "";
        for (const p of this.distancias) {
          if (p.id === this.editedItem.distancia) {
            palabra = p.nombre;
            this.editedItem.nombre = palabra;
          }
        }
      } else {
        this.editedItem.nombre = "";
      }
    },
    dialogChange(value: boolean) {
      this.$emit("dialogChange", value);
    },
    close() {
      this.dialogChange(false);
    },
    save() {
      /*
      if (this.editedItem.distancia > 0) {
        let palabra = "";
        for (const p of this.distancias) {
          if (p.id === this.editedItem.distancia) {
            palabra = p.nombre;
            this.editedItem.nombre = palabra;
          }
        }
      }
      */
      const doc = {...this.editedItem } as any;
      if(isEmpty(this.editedItem.distancia)){
        doc.distancia = 0;
      } else if(isNumber(this.editedItem.distancia)) {
        //doc.distancia = doc.distancia;
      } else if(isNumber(doc.distancia.id)) {
        doc.distancia = doc.distancia.id;
      } else {
        doc.distancia = 0;
      }
      
      DistanciaCarreraService.save(this.$route.params.id, doc)
        .then(() => {
          this.close();
          this.$emit("saved", "someValue");
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 4000,
            icon: "success",
            title: "Categoria",
            text: "Guardado exitosamente"
          });
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: "Categoria",
            text: "No se pudo guardar"
          });
        });
    }
  },
  computed: {},
  watch: {}
});
