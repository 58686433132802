import httpClient from "@/api/http-client";

const ENDPOINT = "/evento/carrera/categoria";

export interface CategoriaCarreraCreateDto {
  id?: number;
  nombre: string;
  descripcion?: string;
  tipoParticipante: string;
  edadMinima: number;
  edadMaxima: number;
  distancia: GetDistanciaDto | null;
}

interface GetDistanciaDto {
  id: number;
}

class CategoriaCarreraService {
  getAll(idCarrera: string): Promise<CategoriaCarreraCreateDto[]> {
    return httpClient.get(`${ENDPOINT}/${idCarrera}`).then(response => {
      if (response.data) {
        return response.data;
      }
    });
  }
  save(idCarrera: string, createDto: CategoriaCarreraCreateDto) {
    return httpClient.post(`${ENDPOINT}/editable/${idCarrera}`, createDto);
  }

  delete(carreraID: string | number, entidadID: string | number) {
    return httpClient.delete(`${ENDPOINT}/${carreraID}/${entidadID}`);
  }
}
export default new CategoriaCarreraService();
