



































































































































































































import Vue from "vue";
import DistanciaCarreraService, {
  DistanciaCarreraCreateDto
} from "@/api/carrera/detalle/DistanciaCarrera.service";
import CategoriaCarreraService, {
  CategoriaCarreraCreateDto
} from "@/api/carrera/detalle/CategoriaCarrera.service";
import CarreraService from "@/api/CarreraService";

import RegistroCarreraService from "@/api/carrera/detalle/RegistroCarreraService";
export default Vue.extend({
  props: ["dialog", "dato"],
  data() {
    return {
      // nuevos
      codigoChip: "",
      entregadoA: "",
      numeroCamiseta: "",
      isEntregarOtraPersona: false,
      //fin nuevos

      editedItem: {
        id: 0
      },
      distancias: [] as DistanciaCarreraCreateDto[],
      categorias: [] as CategoriaCarreraCreateDto[],
      tallas: [] as any[],
      switch1: false,
      documento: {
        talla: null,
        distancia: null as any,
        categoria: null,
        posicion: "",
        guntime: "",
        chiptime: "",
        ritmo: ""
      }
    };
  },
  mounted() {
    this.loadDistancias();
    this.loadCategorias();
    this.editedItem = { ...this.dato };
    //this.loadPapeleta();
  },
  methods: {
    onChangeDistancia() {
      this.documento.categoria = null;
    },
    save() {
      CarreraService.updateResultadoCarreraAdmin(
        this.$route.params.id,
        this.editedItem.id,
        this.documento
      )
        .then(() => {
          this.close();
          this.$emit("saved", "someValue");
          this.$swal({
            icon: "success",
            title: "Resultado Modificado",
            text: "Se guardo correctamente "
          });
        })
        .catch(error => {
          let mensaje = "No se puedo guardar";
          if (error.response && error.response.data) {
            mensaje = error.response.data.message;
          }
          this.$swal({
            icon: "error",
            title: "Resultado Modificado",
            text: mensaje
          });
        });
    },
    dialogChange(value: boolean) {
      this.$emit("dialogChange", value);
    },
    close() {
      this.dialogChange(false);
    },
    loadDistancias() {
      DistanciaCarreraService.getAll(this.$route.params.id)
        .then(response => {
          this.distancias = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Distancias",
            text: "No se pudo obtener"
          });
        });
    },
    loadCategorias() {
      CategoriaCarreraService.getAll(this.$route.params.id)
        .then(response => {
          this.categorias = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Categorias",
            text: "No se pudo obtener"
          });
        });
    }
  },
  computed: {
    categoriasFiltered(): any[] {
      if (this.documento.distancia) {
        const result = this.categorias.filter(
          item =>
            item.distancia &&
            this.documento.distancia &&
            item.distancia.id === this.documento.distancia.id
        );
        return result;
      } else {
        return [];
      }
    }
  },
  watch: {
    switch1: function(newVal) {
      if (newVal) {
        this.documento.posicion = this.dato.posicion;
        this.documento.guntime = this.dato.guntime;
        this.documento.chiptime = this.dato.chiptime;
        this.documento.ritmo = this.dato.ritmo;
      }
    }
  }
});
