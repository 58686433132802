





















































































import Vue from "vue";
import XLSX from "xlsx";
import moment from "moment";
import CuponEventoService, {
  GetCuponEvento
} from "@/api/evento/CuponEventoService";

export default Vue.extend({
  props: ["dialog", "idEvento"],
  data() {
    return {
      editedIndex: -1,
      editedItem: {
        prefijo: "",
        cantidad: 1,
        porcentajeDescuento: 100,
        motivo: ""
      },
      respuesta: {
        lote: "",
        motivo: "",
        cantidad: 0,
        porcentajeDescuento: 0,
        cupones: [] as GetCuponEvento[]
      },
      headers: [
        { text: "#", value: "id" },
        { text: "Lote", value: "cupon.lote" },
        { text: "Código", value: "cupon.codigo" },
        { text: "% Descuento", value: "cupon.porcentajeDescuento" },
        { text: "Motivo", value: "cupon.motivo" }
        //{ text: "Estado", value: "estado" },
        //{ text: "createdOn", value: "createdOn", sortable: false },
        //{ text: "Acción", value: "actions", sortable: false }
      ]
    };
  },
  mounted() {
    //this.editedItem = { ...this.dato };
  },
  methods: {
    dialogChange(value: boolean) {
      this.$emit("dialogChange", value);
    },
    close() {
      this.dialogChange(false);
    },
    save() {
      CuponEventoService.crear(this.$route.params.id, this.editedItem)
        .then(response => {
          this.respuesta = response;
          //this.close();
          this.$emit("saved", "someValue");
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 4000,
            icon: "success",
            title: "Cupones",
            text: "Guardados exitosamente"
          });
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: "Cupones",
            text: "No se pudieron guardar"
          });
        });
    },
    exportExcel() {
      const today = new Date();
      moment.locale("es");
      //const data = XLSX.utils.json_to_sheet(this.datos);
      const data = XLSX.utils.table_to_sheet(
        document.getElementById("tablita-cupones-evento")
      );
      const workbook = XLSX.utils.book_new();
      const filename = `cupones-evento-${this.$route.params.id}_${moment(
        today
      ).format("YYYY-MMMM-DD")}`;
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }
  },
  computed: {},
  watch: {}
});
