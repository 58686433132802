


























import Vue from "vue";
import ReporteCarreraBar from "./ReporteCarreraBar.vue";
export default Vue.extend({
  props: ["value"],
  components: {
    ReporteCarreraBar
  },
  data() {
    return {};
  },
  methods: {
    getReport(query: string, label: string, backgroundColor = "#7EC0AD") {
      const listado = [...this.value[query]];
      listado.sort(function(a, b) {
        return b.total - a.total; // mayor a menor
      });

      return {
        labels: listado.map((v: any) => v.nombre),
        datasets: [
          {
            label: label ?? query.toUpperCase(),
            backgroundColor,
            data: listado.map((v: any) => v.total)
          }
        ]
      };
    }
  },
  computed: {
    /*
    generos() {
      return {
        labels: this.value.generos.map((v: any) => v.nombre),
        datasets: [
          {
            label: "Generos",
            backgroundColor: "#f87979",
            data: this.value.generos.map((v: any) => v.total)
          }
        ]
      };
    },
    tallas() {
      return {
        labels: this.value["tallas"].map((v: any) => v.nombre),
        datasets: [
          {
            label: "Tallas",
            backgroundColor: "#f87979",
            data: this.value["tallas"].map((v: any) => v.total)
          }
        ]
      };
    }
    */
  }
});
