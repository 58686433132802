






























































import Vue from "vue";
//import AuspicianteCarreraService from "@/api/carrera//detalle/AuspicianteCarreraService";
//import RecorridoCarreraService from "@/api/carrera/detalle/RecorridoCarreraService";
import GaleriaCarreraService from "@/api/carrera/detalle/GaleriaCarrera.service";
import CarreraService from "@/api/CarreraService";
import { AxiosError } from "axios";
import { isArray } from "class-validator";

//import moment from "moment";
export default Vue.extend({
  components: {},
  props: ["dialog", "carreraID"],
  data() {
    return {
      valid: true,
      files: [],
      editedIndex: -1,
      editedItem: {
        id: 0,
        link: "",
        tipo: "",
        posicion: 1
      },
      /*
      imageRules: [
        (value: File) =>
          !value || value.size < 2000000 || "Imagen debe pesar menos de 2 MB!"
      ],
      */
      imageRules: [
        (value: File) =>
          !value ||
          value.size < 5 * 1024 * 1024 ||
          "Carga debe pesar menos de 5 MB!"
      ],
      // variables de resultados
      loading: false,
      modoTest: true
    };
  },
  created() {
    //this.editedItem = { ...this.dato };
  },
  methods: {
    dialogChange(value: boolean) {
      this.$emit("dialogChange", value);
    },
    validate() {
      console.log(this.editedItem);
      //this.$refs.form.validate();
      const isValid = (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();
      if (isValid) {
        this.save();
      }
    },
    close() {
      this.dialogChange(false);
    },
    save() {
      const formData = new FormData();
      for (let i = 0; i < this.files.length; i++) {
        formData.append("file", this.files[i]);
      }

      formData.append("test", this.modoTest ? "1" : "0");
      formData.append("idCarrera", String(this.carreraID));

      this.loading = true;
      CarreraService.saveResultadosCarrera(formData)
        .then(() => {
          this.close();
          this.$emit("saved", "someValue");
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 4000,
            icon: "success",
            title: "Resultado",
            text: "Guardado exitosamente"
          });
        })
        .catch((error: AxiosError) => {
          let mensaje = "No se pudo guardar";
          if (error.response?.data && error.response.data.message) {
            if (isArray(error.response.data.message)) {
              let messageHtml = "";
              for (const message of error.response.data.message) {
                messageHtml += `<p>${message}</p> <br>`;
              }
              mensaje = `<div style="max-height: 350px; overflow-y: auto; background-color: #f7f7f7; padding: 5px;">${messageHtml}</div>`;
            } else {
              mensaje = error.response.data.message;
            }
          }
          this.$swal({
            position: "center",
            icon: "error",
            title: "Resultado",
            html: mensaje,
            allowOutsideClick: false
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  computed: {
    /*
    (value: string) =>
          vali || "Debe ser una url valida como https://www.google.com"
          */
  },
  watch: {}
});
