
























































import Vue from "vue";
import BannerPublicitarioCarreraService from "@/api/carrera//detalle/BannerPublicitarioCarreraService";

//import moment from "moment";
export default Vue.extend({
  components: {},
  props: ["dialog", "dato"],
  data() {
    return {
      valid: true,
      file: null,
      editedIndex: -1,
      editedItem: {
        id: 0,
        url: ""
      },
      imageRules: [
        (value: File) =>
          !value || value.size < 2000000 || "Imagen debe pesar menos de 2 MB!"
      ]
    };
  },
  created() {
    this.editedItem = { ...this.dato };
  },
  methods: {
    dialogChange(value: boolean) {
      this.$emit("dialogChange", value);
    },
    validate() {
      console.log(this.editedItem);
      //this.$refs.form.validate();
      const isValid = (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();
      if (isValid) {
        this.save();
      }
    },
    close() {
      this.dialogChange(false);
    },
    save() {
      const formData = new FormData();
      formData.append("image", this.file || "");
      formData.append("url", this.editedItem.url);
      const idBanner = this.editedItem.id;
      BannerPublicitarioCarreraService.editBanner(idBanner, formData)
        .then(() => {
          this.close();
          this.$emit("saved", "someValue");
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 4000,
            icon: "success",
            title: "Banner Publicitario",
            text: "Editado exitosamente"
          });
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: "Banner Publicitario",
            text: "No se pudo guardar"
          });
        });
    },

    isValidUrl(value: string): boolean {
      if (value) {
        let url;
        try {
          url = new URL(value);
        } catch (_) {
          return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
      }
      return true;
    }
  },
  computed: {
    /*
    (value: string) =>
          vali || "Debe ser una url valida como https://www.google.com"
          */
  },
  watch: {}
});
