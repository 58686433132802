



























































































import Vue from "vue";
import CategoriaCarreraService from "@/api/carrera/detalle/CategoriaCarrera.service";
import DistanciaCarreraService, {
  DistanciaCarreraCreateDto
} from "@/api/carrera/detalle/DistanciaCarrera.service";
export default Vue.extend({
  props: ["dialog", "dato"],
  data() {
    return {
      editedIndex: -1,
      editedItem: {
        nombre: "",
        descripcion: "",
        tipoParticipante: "",
        edadMinima: 0,
        edadMaxima: 0,
        distancia: null,
        isGeneral: false
      },
      tipoParticipanteOptions: [
        { text: "Hombre", value: "M" },
        { text: "Mujer", value: "F" }
        //{ text: "Mixto", value: "T" }
      ],
      distancias: [] as DistanciaCarreraCreateDto[]
    };
  },
  mounted() {
    this.loadDistancias();
    this.editedItem = { ...this.dato };
  },
  methods: {
    dialogChange(value: boolean) {
      this.$emit("dialogChange", value);
    },
    close() {
      this.dialogChange(false);
    },
    save() {
      CategoriaCarreraService.save(this.$route.params.id, this.editedItem)
        .then(() => {
          this.close();
          this.$emit("saved", "someValue");
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 4000,
            icon: "success",
            title: "Categoria",
            text: "Guardado exitosamente"
          });
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: "Categoria",
            text: "No se pudo guardar"
          });
        });
    },
    loadDistancias() {
      DistanciaCarreraService.getAll(this.$route.params.id)
        .then(response => {
          this.distancias = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Distancias",
            text: "No se pudo obtener"
          });
        });
    }
  },
  computed: {},
  watch: {}
});
