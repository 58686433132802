import httpClient from "@/api/http-client";

const ENDPOINT = "/evento/carrera/kit-carrera";

export interface KitCreateDto {
  id?: number;
  nombre: string;
  cantidad: number;
  entrada: number;
}

class KitCarreraService {
  getAll(idCarrera: string): Promise<KitCreateDto[]> {
    return httpClient.get(`${ENDPOINT}/${idCarrera}`).then(response => {
      if (response.data) {
        return response.data;
      }
    });
  }
  save(idCarrera: string, createDto: KitCreateDto) {
    if (createDto.id && createDto.id > 0) {
      return this.edit(idCarrera, createDto);
    } else {
      return this.create(idCarrera, createDto);
    }
  }

  create(idCarrera: string, createDto: KitCreateDto) {
    return httpClient.post(`${ENDPOINT}/${idCarrera}`, createDto);
  }
  edit(idCarrera: string, createDto: KitCreateDto) {
    return httpClient.put(`${ENDPOINT}/${idCarrera}`, createDto);
  }
}
export default new KitCarreraService();
