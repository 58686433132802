

















































import CategoriaCarreraAdd from "./CategoriaCarreraAdd.vue";
import Vue from "vue";
import CategoriaCarreraService, {
  CategoriaCarreraCreateDto
} from "@/api/carrera/detalle/CategoriaCarrera.service";

export default Vue.extend({
  components: {
    CategoriaCarreraAdd
  },
  data() {
    return {
      dialog: false,
      dato: {
        nombre: "",
        descripcion: "",
        tipoParticipante: "",
        edadMinima: 0,
        edadMaxima: 0,
        distancia: null,
        isGeneral: false
      } as CategoriaCarreraCreateDto,
      headers: [
        { text: "Id", value: "id" },
        { text: "Nombre", value: "nombre" },
        { text: "Distancia", value: "distancia.nombre" },
        { text: "Tipo Participante", value: "tipoParticipante" },
        //{ text: "Descripcion", value: "descripcion" },
        { text: "Edad Min", value: "edadMinima" },
        { text: "Edad Max", value: "edadMaxima" },
        { text: "Display", value: "displayCategoria" },
        //{ text: "Estado", value: "estado" },
        //{ text: "createdOn", value: "createdOn", sortable: false },
        { text: "Acción", value: "actions", sortable: false }
      ],
      datos: [] as CategoriaCarreraCreateDto[]
    };
  },
  methods: {
    setDefaultItem() {
      const newDto = {
        nombre: "",
        descripcion: "",
        tipoParticipante: "",
        edadMinima: 0,
        edadMaxima: 0,
        distancia: null,
        isGeneral: false
      };
      this.dato = newDto;
    },
    editItem(item: CategoriaCarreraCreateDto) {
      this.dato = item;
      this.dialogChange(true);
    },
    newItem() {
      this.setDefaultItem();
      this.dialogChange(true);
    },
    dialogChange(value: boolean) {
      this.dialog = value;
    },
    getAll() {
      CategoriaCarreraService.getAll(this.$route.params.id)
        .then(response => {
          this.datos = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Categorias",
            text: "No se pudo obtener"
          });
        });
    },
    deleteItem(item: {id: number}) {
      CategoriaCarreraService.delete(this.$route.params.id, item.id)
        .then(() => {
          this.datos.splice(0);
          this.getAll();
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Categoria",
            text: "No se pudo borrar, verifique que no tenga registro asociado"
          });
        });
    }
  },
  mounted() {
    this.getAll();
  },
  watch: {
    dialog: function(newVal) {
      if (newVal === false) {
        this.setDefaultItem();
      }
    }
  }
});
