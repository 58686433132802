




























































import Vue from "vue";
import RecorridoAdd from "./RecorridoAdd.vue";
import RecorridoCarreraService from "@/api/carrera/detalle/RecorridoCarreraService";
export default Vue.extend({
  components: {
    RecorridoAdd
  },
  data() {
    return {
      dialog: false,
      dato: {
        id: 0,
        link: "",
        tipo: "",
        posicion: 1
      },
      datos: [] as any[]
    };
  },
  methods: {
    newItem() {
      this.dato = {
        id: 0,
        link: "",
        tipo: "",
        posicion: 1
      };
      this.dialogChange(true);
    },
    editItem(item: {
      id: number;
      link: string;
      tipo: string;
      posicion: number;
    }) {
      this.dato = {
        id: item.id,
        link: item.link,
        tipo: item.tipo,
        posicion: item.posicion
      };
      this.dialogChange(true);
    },
    dialogChange(value: boolean) {
      this.dialog = value;
    },
    getBanners() {
      RecorridoCarreraService.getRecorridos(this.$route.params.id)
        .then(response => {
          this.datos.splice(0);
          this.datos = [...response];
        })
        .catch(() => {
          console.log("No se cargaron los recorridos");
        }); //fin get Banners
    },
    deleteRecorrido(recorridoID: number) {
      RecorridoCarreraService.deleteRecorrido(
        this.$route.params.id,
        recorridoID
      )
        .then(() => {
          this.datos.splice(0);
          this.getBanners();
        })
        .catch(() => {
          console.log("No se pudo borrar el recorrido");
        });
    }
  },
  mounted() {
    this.getBanners();
  }
});
