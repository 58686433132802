










































































import Vue from "vue";
import AuspicianteCarreraService from "@/api/carrera//detalle/AuspicianteCarreraService";
import RecorridoCarreraService from "@/api/carrera/detalle/RecorridoCarreraService";

//import moment from "moment";
export default Vue.extend({
  components: {},
  props: ["dialog", "dato"],
  data() {
    return {
      valid: true,
      file: null,
      editedIndex: -1,
      editedItem: {
        id: 0,
        link: "",
        tipo: "",
        posicion: 1
      },
      imageRules: [
        (value: File) =>
          !value || value.size < 2000000 || "Imagen debe pesar menos de 2 MB!"
      ],
      tipoOptions: [
        { text: "Recorrido", value: "1" },
        { text: "Lugar", value: "2" },
        { text: "Mapa", value: "3" }
      ]
    };
  },
  created() {
    this.editedItem = { ...this.dato };
  },
  methods: {
    dialogChange(value: boolean) {
      this.$emit("dialogChange", value);
    },
    validate() {
      console.log(this.editedItem);
      //this.$refs.form.validate();
      const isValid = (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();
      if (isValid) {
        this.save();
      }
    },
    close() {
      this.dialogChange(false);
    },
    save() {
      const formData = new FormData();
      formData.append("image", this.file || "");
      formData.append("link", this.editedItem.link);
      formData.append("tipo", this.editedItem.tipo);
      formData.append("posicion", String(this.editedItem.posicion));
      const recorridoID = this.editedItem.id;
      const carreraID = this.$route.params.id;

      //const idBanner = this.editedItem.id;
      RecorridoCarreraService.saveRecorrido(carreraID, recorridoID, formData)
        .then(() => {
          this.close();
          this.$emit("saved", "someValue");
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 4000,
            icon: "success",
            title: "Recorrido",
            text: "Guardado exitosamente"
          });
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: "Recorrido",
            text: "No se pudo guardar"
          });
        });
    },

    isValidUrl(value: string): boolean {
      if (value) {
        let url;
        try {
          url = new URL(value);
        } catch (_) {
          return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
      }
      return true;
    }
  },
  computed: {
    /*
    (value: string) =>
          vali || "Debe ser una url valida como https://www.google.com"
          */
  },
  watch: {}
});
