






































































































import Vue from "vue";
import PrecioCarreraService from "@/api/carrera/detalle/PrecioCarreraService";
import DistanciaCarreraService, {
  DistanciaCarreraCreateDto
} from "@/api/carrera/detalle/DistanciaCarrera.service";
import DateTimePicker from "@/components/shared/DateTimePicker.vue";
export default Vue.extend({
  props: ["dialog", "dato"],
  components: {
    DateTimePicker
  },
  data() {
    return {
      editedIndex: -1,
      editedItem: {
        nombre: "",
        descripcion: "",
        tipoDescriptor: "",
        position: 0,
        fechaVigencia: null,
      },
      mostrarOptions: [
        { value: "1", text: "Si" },
        { value: "2", text: "No" }
      ],
      distancias: [] as DistanciaCarreraCreateDto[],
      descriptorOptions: [
        { value: "1", text: "Regular" },
        { value: "2", text: "Discapacitado" },
        { value: "3", text: "Menor de Edad (< 12 años)" },
        { value: "4", text: "Tercera Edad (>= 65 años)" },
        { value: "5", text: "Otros" }
        //{ value: "11", text: "Suscripción" },
      ],
      descriptoresEditables: ["1", "2", "3", "4", "5"]
    };
  },
  mounted() {
    this.getDistancias();
    this.editedItem = { ...this.dato };
  },
  methods: {
    dialogChange(value: boolean) {
      this.$emit("dialogChange", value);
    },
    close() {
      this.dialogChange(false);
    },
    save() {
      PrecioCarreraService.save(this.$route.params.id, this.editedItem)
        .then(() => {
          this.close();
          this.$emit("saved", "someValue");
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 4000,
            icon: "success",
            title: "Precio",
            text: "Guardado exitosamente"
          });
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: "Precio",
            text: "No se pudo guardar"
          });
        });
    },
    getDistancias() {
      DistanciaCarreraService.getAll(this.$route.params.id)
        .then(response => {
          this.distancias = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Distancias",
            text: "No se pudo obtener"
          });
        });
    }
    //
  },
  computed: {
    itemDesactivado(): boolean {
      const lista = ["1", "2", "3", "4"];
      return lista.indexOf(this.editedItem.tipoDescriptor) >= 0;
    }
  },
  watch: {}
});
