import httpClient from "@/api/http-client";

const ENDPOINT = "/evento/carrera/talla-camiseta";

export interface TallaCamisetaDto {
  id?: number;
  stockBase: number;
  stockSequence: number;
  talla: {
    id: number;
    nombre: string;
    descripcion: string;
  };
}

export interface TallaCamisetaEditDto {
  tallasCamisetas: TallaCamisetaDto[];
}

class TallaCamisetaCarreraService {
  getTallas(idCarrera: string) {
    return httpClient.get(`${ENDPOINT}/${idCarrera}`).then(response => {
      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    });
  }

  getTallasEditables(idCarrera: string) {
    return httpClient
      .get(`${ENDPOINT}/editable/${idCarrera}`)
      .then(response => {
        if (response.data) {
          return response.data;
        } else {
          return [];
        }
      });
  }

  saveTallasEditables(idCarrera: string, editDto: TallaCamisetaEditDto) {
    return httpClient.put(`${ENDPOINT}/editable/${idCarrera}`, editDto);
  }
}

export default new TallaCamisetaCarreraService();
