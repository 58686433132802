
































































import Vue from "vue";
import GaleriaAdd from "./GaleriaAdd.vue";
//import RecorridoCarreraService from "@/api/carrera/detalle/RecorridoCarreraService";
import GaleriaCarreraService from "@/api/carrera/detalle/GaleriaCarrera.service";
export default Vue.extend({
  components: {
    GaleriaAdd
  },
  data() {
    return {
      dialog: false,
      dato: {
        id: 0,
        link: "",
        tipo: "",
        posicion: 1
      },
      datos: [] as any[],
      fotos: [] as any[],
      galeria: {
        items: [],
        meta: {
          totalItems: 0,
          itemCount: 0,
          itemsPerPage: 10,
          totalPages: 0,
          currentPage: 1
        }
      } as any
    };
  },
  methods: {
    newItem() {
      this.dato = {
        id: 0,
        link: "",
        tipo: "",
        posicion: 1
      };
      this.dialogChange(true);
    },
    editItem(item: {
      id: number;
      link: string;
      tipo: string;
      posicion: number;
    }) {
      this.dato = {
        id: item.id,
        link: item.link,
        tipo: item.tipo,
        posicion: item.posicion
      };
      this.dialogChange(true);
    },
    dialogChange(value: boolean) {
      this.dialog = value;
    },
    infiniteScrolling(entries: any, observer: any, isIntersecting: any) {
      if (this.galeria.meta.currentPage < this.galeria.meta.totalPages) {
        this.galeria.meta.currentPage++;
        this.getBanners();
      }
    },
    onSaved() {
      this.fotos = [];
      this.galeria.currentPage = 1;
      this.galeria.totalPages = 0;
      this.getBanners();
    },
    getBanners() {
      const params = new URLSearchParams({
        page: this.galeria.meta.currentPage.toString(),
        limit: "10"
      }).toString();

      GaleriaCarreraService.getAllPaginado(this.$route.params.id, params)
        .then(respuesta => {
          this.galeria = respuesta;
          this.fotos.push(...respuesta.items);
        })
        .catch(() => {
          //
        });
    },
    deleteFoto(recorridoID: number) {
      GaleriaCarreraService.delete(this.$route.params.id, recorridoID)
        .then(() => {
          this.datos.splice(0);
          this.getBanners();
        })
        .catch(() => {
          console.log("No se pudo borrar la foto");
        });
    }
  },
  mounted() {
    this.getBanners();
  }
});
