
































































































import Vue from "vue";

//import EncuestaService from "@/api/encuesta/EncuestaService";
import RegistroCarreraService from "@/api/carrera/detalle/RegistroCarreraService";
export default Vue.extend({
  props: ["dialog", "dato"],
  data() {
    return {
      saving: false,
      // nuevos
      codigoChip: "",
      entregadoA: "",
      numeroCamiseta: "",
      isEntregarOtraPersona: false,
      //fin nuevos

      editedItem: {
        id: 0
      }
    };
  },
  mounted() {
    this.editedItem = { ...this.dato };
    //this.loadPapeleta();
  },
  methods: {
    onChangeIsEntregarOtraPersona(value: boolean) {
      if (!value) {
        this.entregadoA = "";
      }
    },
    save() {
      if (this.saving) {
        return;
      }
      this.saving = true;
      RegistroCarreraService.entregarRegistroPendienteEntrega(
        this.editedItem.id,
        {
          codigoChip: this.codigoChip,
          entregadoA: this.entregadoA,
          numeroCamiseta: this.numeroCamiseta
        }
      )
        .then(() => {
          this.close();
          this.$emit("saved", "someValue");
          this.$swal({
            icon: "success",
            title: "Entrega",
            text: "Se guardo correctamente "
          });
        })
        .catch(error => {
          let mensaje = "No se puedo guardar";
          if (error.response && error.response.data) {
            mensaje = error.response.data.message;
          }
          this.$swal({
            icon: "error",
            title: "Entrega",
            text: mensaje
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    dialogChange(value: boolean) {
      this.$emit("dialogChange", value);
    },
    close() {
      this.dialogChange(false);
    }
  },
  computed: {
    isPresencial(): boolean {
      return (
        this.dato.carrera.modalidad === "P" ||
        this.dato.carrera.modalidad === "PCV"
      );
    }
  },
  watch: {}
});
