























































import Vue from "vue";
import KitCarreraService, {
  KitCreateDto
} from "@/api/carrera/detalle/KitCarreraService";

export default Vue.extend({
  props: ["dialog", "dato"],
  data() {
    return {
      editedIndex: -1,
      editedItem: {
        nombre: "",
        cantidad: 0,
        entrada: 0
      }
    };
  },
  mounted() {
    this.editedItem = { ...this.dato };
  },
  methods: {
    dialogChange(value: boolean) {
      this.$emit("dialogChange", value);
    },
    close() {
      this.dialogChange(false);
    },
    save() {
      KitCarreraService.save(this.$route.params.id, this.editedItem)
        .then(() => {
          this.close();
          this.$emit("saved", "someValue");
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 4000,
            icon: "success",
            title: "Kit",
            text: "Guardado exitosamente"
          });
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: "Kit",
            text: "No se pudo guardar"
          });
        });
    }
  },
  computed: {},
  watch: {}
});
