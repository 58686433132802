var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('h2',[_vm._v("Registros")])])],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.datos,"items-per-page":10,"search":_vm.search,"id":"tablita"},scopedSlots:_vm._u([{key:"top",fn:function(){return [(false)?_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Pagados: "+_vm._s(_vm.datos.length))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-toolbar-title',[_vm._v("Recaudado: $"+_vm._s(_vm.totalRecaudado))]),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.downloadExport()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-download ")]),_vm._v(" Descargar ")],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.estados",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":item.pedido.estadoEnvio !== '8' ? 'green' : ''}},'v-icon',attrs,false),on),[_vm._v("mdi-map-marker")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.pedido.estadoEnvio !== "8" ? "Si tiene dirección" : "No tiene dirección"))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":item.estadoEntrega === '2' ? 'green' : ''}},'v-icon',attrs,false),on),[_vm._v("mdi-truck")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.displayEstadoEntrega))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isInRole(['1']))?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"green"},on:{"click":function($event){return _vm.editItem(item.pedido)}}},[_vm._v("mdi-credit-card")]):_vm._e()]}}],null,true)}),(_vm.dialog)?_c('PapeletaAprobar',{attrs:{"dialog":_vm.dialog,"dato":_vm.dato,"modalidad":{ aprobable: false, rechazable: false, anulable: true }},on:{"saved":function($event){return _vm.getAll()},"dialogChange":_vm.dialogChange}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }