import httpClient from "@/api/http-client";
import { GetPaginateDto } from "@/api/interfaces";

const ENDPOINT = "/evento/carrera/galeria";

export interface GaleriaCarreraDto {
  url: string;
}

class GaleriaCarreraService {
  getAllPaginado(
    carreraID: string | number,
    query: string
  ): Promise<GetPaginateDto<any>> {
    return httpClient
      .get(`${ENDPOINT}/${carreraID}/paginado?${query}`)
      .then(response => {
        if (response.data) {
          return response.data;
        }
      });
  }

  getAll(carreraID: string | number): Promise<any[]> {
    return httpClient.get(`${ENDPOINT}/${carreraID}`).then(response => {
      if (response && response.data) {
        return response.data;
      } else {
        return [];
      }
    });
  }

  private create(carreraID: string | number, createDto: FormData) {
    return httpClient.post(`${ENDPOINT}/${carreraID}`, createDto);
  }

  private update(
    carreraID: string | number,
    id: string | number,
    createDto: FormData
  ) {
    return httpClient.put(`${ENDPOINT}/${carreraID}/${id}`, createDto);
  }

  save(carreraID: string | number, id: string | number, dto: FormData) {
    //
    if (id) {
      return this.update(carreraID, id, dto);
    } else {
      return this.create(carreraID, dto);
    }
  }

  delete(carreraID: string | number, id: string | number) {
    return httpClient.delete(`${ENDPOINT}/${carreraID}/${id}`);
  }
}

export default new GaleriaCarreraService();
