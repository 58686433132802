


















































import CategoriaDistanciaCarreraAdd from "./DistanciaCarreraAdd.vue";
import Vue from "vue";
import DistanciaCarreraService, {
  DistanciaCarreraCreateDto
} from "@/api/carrera/detalle/DistanciaCarrera.service";

import DistanciaService, { DistanciaCreateDto } from "@/api/DistanciaService";

export default Vue.extend({
  components: {
    CategoriaDistanciaCarreraAdd
  },
  data() {
    return {
      dialog: false,
      distanciasBase: [] as DistanciaCreateDto[],
      dato: {
        nombre: ""
      } as DistanciaCarreraCreateDto,
      headers: [
        { text: "Id", value: "id" },
        { text: "Nombre", value: "nombre" },
        { text: "Edad Min", value: "edadMinima" },
        { text: "Edad Max", value: "edadMaxima" },
        { text: "Pos.", value: "position" },
        //{ text: "Estado", value: "estado" },
        //{ text: "createdOn", value: "createdOn", sortable: false },
        { text: "Acción", value: "actions", sortable: false }
      ],
      datos: [] as DistanciaCarreraCreateDto[]
    };
  },
  methods: {
    setDefaultItem() {
      const newDto = {
        nombre: "",
        distancia: 0,
        edadMinima: 0,
        edadMaxima: 0,
        position: 0,
        isGeneralM: true,
        isGeneralF: true,
      };
      this.dato = newDto;
    },
    editItem(item: DistanciaCarreraCreateDto) {
      this.dato = item;
      this.dialogChange(true);
    },
    newItem() {
      this.setDefaultItem();
      this.dialogChange(true);
    },
    dialogChange(value: boolean) {
      this.dialog = value;
    },
    getAll() {
      DistanciaCarreraService.getAll(this.$route.params.id)
        .then(response => {
          this.datos = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Distancias",
            text: "No se pudo obtener"
          });
        });
    },
    getDistancias() {
      DistanciaService.getAll()
        .then(response => {
          this.distanciasBase = response;
          this.distanciasBase.push({ id: 0, nombre: "Otras" });
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: "Distancias",
            text: "No se pudo obtener, refresque la pagina"
          });
        });
    },
    deleteItem(item: {id: number}) {
      DistanciaCarreraService.delete(this.$route.params.id, item.id)
        .then(() => {
          this.datos.splice(0);
          this.getAll();
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Distancia",
            text: "No se pudo borrar, verifique que no tenga categoria o registro asociado"
          });
        });
    }
  },
  mounted() {
    this.getAll();
    this.getDistancias();
  },
  watch: {
    dialog: function(newVal) {
      if (newVal === false) {
        this.setDefaultItem();
      }
    }
  }
});
