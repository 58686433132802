



























import Vue from "vue";
import RegistroFromExcelCargar from "./RegistroFromExcelCargar.vue";
import CarreraService, { GetResultadoCarrera } from "@/api/CarreraService";
export default Vue.extend({
  components: {
    RegistroFromExcelCargar,
  },
  data() {
    return {
      dialog: false,
      dialogCargar: false,
      search: "",
      headers: [
        { text: "#", value: "id" },
        { text: "nombre", value: "persona.displayNombreCompleto" },
        { text: "chip", value: "chipCronometraje.codigo" },
        { text: "posición", value: "posicion" },
        { text: "guntime", value: "guntime" },
        { text: "chiptime", value: "chiptime" },
        { text: "ritmo", value: "ritmo" },
        { text: "Acción", value: "actions", sortable: false }
      ],
      datos: [] as GetResultadoCarrera[],
      dato: {
        id: 0
      } as GetResultadoCarrera
    };
  },
  methods: {
    // editar resultado
    setDefaultItem() {
      const newDto = {
        id: 0
      };
      //this.dato = newDto;
    },
    editItem(item: GetResultadoCarrera) {
      this.dato = item;
      this.dialogChange(true);
    },
    dialogChange(value: boolean) {
      this.dialog = value;
    },
    // carga resultado
    newItemCargar() {
      this.dialogChangeCargar(true);
    },
    dialogChangeCargar(value: boolean) {
      this.dialogCargar = value;
    },
    getAll() {
      CarreraService.getResultadosCarreraAdmin(this.$route.params.id, "")
        .then(response => {
          this.datos = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Resultados",
            text: "No se pudo obtener"
          });
        });
    },
    deleteAll() {
      CarreraService.deleteResultadosAdmin(this.$route.params.id)
        .then(() => {
          this.getAll();
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Resultados",
            text: "No se pudo obtener"
          });
        });
    },
    deleteItem(item: GetResultadoCarrera) {
      CarreraService.deleteResultadoAdmin(this.$route.params.id, item.id)
        .then(() => {
          this.getAll();
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Resultado",
            text: "No se pudo borrar"
          });
        });
    }
  },
  mounted() {
    this.getAll();
  }
});
