















































import CuponAdd from "./CuponAdd.vue";
import Vue from "vue";
import CuponEventoService, {
  GetCuponEvento
} from "@/api/evento/CuponEventoService";

export default Vue.extend({
  components: {
    CuponAdd
  },
  data() {
    return {
      dialog: false,
      headers: [
        { text: "Id", value: "id" },
        { text: "Código", value: "cupon.codigo" },
        { text: "% Desc.", value: "cupon.porcentajeDescuento" },
        { text: "Motivo", value: "cupon.motivo" },
        { text: "Creado Por", value: "cupon.creadoPor.nombre" },
        { text: "Canjeado Por", value: "cupon.canjeadoPor.nombre" }
        //{ text: "Estado", value: "estado" },
        //{ text: "createdOn", value: "createdOn", sortable: false },
        //{ text: "Acción", value: "actions", sortable: false }
      ],
      idEvento: "0",
      datos: [] as GetCuponEvento[]
    };
  },
  methods: {
    /*
    editItem(item: DistanciaCarreraCreateDto) {
      this.dato = item;
      this.dialogChange(true);
    },
    newItem() {
      this.setDefaultItem();
      this.dialogChange(true);
    },
    */
    newCupon() {
      this.idEvento = this.$route.params.id;
      this.dialogChange(true);
    },
    dialogChange(value: boolean) {
      this.dialog = value;
    },
    getAll() {
      CuponEventoService.getAll(this.$route.params.id)
        .then(response => {
          this.datos = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Cupones",
            text: "No se pudo obtener"
          });
        });
    }
  },
  mounted() {
    this.getAll();
  },
  watch: {}
});
