














































































































































































import Vue from "vue";
import { AxiosError } from "axios";
import TallaCamisetaCarreraService from "@/api/carrera/detalle/TallaCamisetaCarreraService";
type Stock = {
  stockBase: number;
  stockSequence: number;
};
export default Vue.extend({
  data() {
    return {
      saving: false,
      isAvanzado: true,
      datos: [],
      capacidad: 0,
      restante: 0,
      liberado: 0,
      estadoOptions: [
        { value: "1", text: "Activo" },
        { value: "2", text: "Inactivo" }
      ]
    };
  },
  methods: {
    goInventario(articuloID: number) {
      this.$router.push(`/admin/inventario/general/articulo/${articuloID}`);
    },
    getTallasEditables() {
      TallaCamisetaCarreraService.getTallasEditables(this.$route.params.id)
        .then(response => {
          this.datos = response;
          //this.calculateStokc();
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Tallas Editables",
            text: "No se pudo obtener"
          });
        });
    },
    save() {
      if (this.saving) {
        return;
      }
      const dto = { tallasCamisetas: this.datos };
      this.saving = true;
      TallaCamisetaCarreraService.saveTallasEditables(
        this.$route.params.id,
        dto
      )
        .then(() => {
          this.getTallasEditables();
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "success",
            title: "Tallas",
            text: "Guardadas"
          });
        })
        .catch((error: AxiosError) => {
          let mensaje = "No guardadas";
          if (error.response?.data && error.response.data.message) {
            mensaje = error.response.data.message;
          }
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Tallas",
            text: mensaje
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    calculateStokc() {
      for (const i in this.datos) {
        const item = this.datos[i] as Stock;
        this.capacidad += item.stockBase;
        this.restante += item.stockSequence;
      }
      this.liberado = this.capacidad - this.restante;
    }
  },
  mounted() {
    this.getTallasEditables();
  }
});
