import httpClient from "@/api/http-client";

const ENDPOINT = "/evento/carrera/precio";

export interface PrecioCreateDto {
  id?: number;
  nombre: string;
  descripcion: string;
  fechaVigencia?: Date | string | null;
}

class PrecioCarreraService {
  getAll(idCarrera: string): Promise<PrecioCreateDto[]> {
    return httpClient
      .get(`${ENDPOINT}/editable/${idCarrera}`)
      .then(response => {
        if (response.data) {
          return response.data;
        }
      });
  }
  save(idCarrera: string, createDto: PrecioCreateDto) {
    return httpClient.post(`${ENDPOINT}/editable/${idCarrera}`, createDto);
  }
}
export default new PrecioCarreraService();
