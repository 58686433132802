






























































import Vue from "vue";
import AuspiciantedAdd from "@/components/admin/evento/carrera/auspiciante/AuspicianteAdd.vue";
import AuspicianteCarreraService from "@/api/carrera/detalle/AuspicianteCarreraService";
export default Vue.extend({
  components: {
    AuspiciantedAdd
  },
  data() {
    return {
      dialog: false,
      dato: {
        id: 0,
        title: "",
        url: "",
        name: ""
      },
      datos: []
    };
  },
  methods: {
    newItem() {
      this.dato = {
        id: 0,
        url: "",
        title: "",
        name: ""
      };
      this.dialogChange(true);
    },
    editItem(item: { id: number; url: string; title: string; name: string }) {
      this.dato = {
        id: item.id,
        url: item.url,
        title: item.title,
        name: item.name
      };
      this.dialogChange(true);
    },
    dialogChange(value: boolean) {
      this.dialog = value;
    },
    getBanners() {
      AuspicianteCarreraService.getBanners(this.$route.params.id)
        .then(response => {
          this.datos = response.data;
        })
        .catch(() => {
          console.log("No se cargaron los auspiciantes");
        }); //fin get Banners
    },
    getBanner(fileName: string) {
      return AuspicianteCarreraService.getImageUrl(fileName);
    },
    deleteAuspiciante(idAuspiciante: number) {
      AuspicianteCarreraService.deleteAuspiciante(idAuspiciante)
        .then(() => {
          this.datos = [];
          this.getBanners();
        })
        .catch(() => {
          console.log("No se pudo borrar el auspiciante");
        });
    }
  },
  mounted() {
    this.getBanners();
  }
});
